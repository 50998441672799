.banner {
  min-height: calc(100vh - 80px);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./images/blur.webp);
  background-size: cover;
}

.logo {
  width: 100px;
}

@media screen and (min-width: 1200px) {
  .logo {
    width: 150px;
  }

  .banner {
    min-height: calc(100vh - 200px);
  }
}


.header {
  color: white;
  z-index: 10;
  font-size: 50px;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  margin-left: 2;
  font-weight: 300;
}

@media screen and (min-width: 1200px) {
  .header {
    font-size: 5rem;
    text-align: start;
  }
}

body {
  font-family: "Alegreya", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}


.priceTable {
  color: white;
  font-size: 2.2rem;
  font-weight: 500;

  .banner-content__price {
    margin-bottom: 10px;
  }

  .banner-content__price>svg {
    font-size: 30px;
    font-weight: 100;
    color: #08b4da;
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .priceTable {
    font-size: 6vw;
    padding: 10px 0 0 20px;

    .banner-content__price {
      margin-bottom: 1px;
    }
  }

  .header {
    font-size: 10vw;
  }

  .banner-list {
    padding: 0;
  }

  .mainNav {
    .contactGroup {
      a {
        font-size: 1rem;
      }
    }
  }

}

ul,
li {
  list-style-type: none;
}

.mainNav {
  background: #caff6e;
  --bs-navbar-color: black;
  --bs-link-color-rgb: black;

  .navbar-toggler.collapsed {
    border: none;
    font-size: 25px;
    margin-top: 0;
  }

  .navbar-toggler {
    margin-top: 12px;
  }

  a {
    text-decoration: none;
  }

  .nav-link {
    padding: 0;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    vertical-align: middle;
    line-height: 100px;
    text-align: center;

    &:hover {
      color: rgb(34, 140, 233);
    }
  }

  .mobilePhone {
    line-height: 80px;
  }
}

@media screen and (max-width: 992px) {
  .expandCont {
    z-index: 100;
    position: absolute;
    background: #caff6e;
  }

  .mainNav {
    height: 80px;
  }
}

.infoBanner {
  margin-bottom: 20px;
  flex-grow: 1;
}

@media screen and (min-width: 1200px) {
  .infoBanner {
    margin-bottom: 12%;
    flex-grow: 0;
  }
}

.contactBlock {
  height: 100px;

  .nav-link {
    text-align: start;
    line-height: 0;
    font-family: 'Open Sans Condensed', sans-serif;
    font-display: swap;
  }

  svg {
    margin-right: 7px;
  }
}

.locationIcon {
  font-size: 40px;
}

.bg-grey {
  background-color: #FAFAFA;
}

.padding40 {
  padding: 40px 0;
}

.clients-logo li {
  padding: 0 20px
}

.clients-logo li img {
  opacity: .4;
  filter: alpha(opacity=40)
}

.benefitList {
  background: linear-gradient(-45deg, rgba(133, 255, 2, 0.2), rgba(102, 255, 0, 0.2), rgba(26, 255, 0, 0.2), rgba(255, 251, 0, 0.2));
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

  h4 {
    margin-top: 10px;
    font-weight: 500;
    padding: 0 6px;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}

@media screen and (min-width: 768px) {
  .benefitList {
    h4 {
      padding: 12px 10px;
      font-size: 25px;
    }
  }
}

.iconImg {
  width: 7rem;
}

@media screen and (min-width: 1200px) {
  .proposals {
    margin-left: 200px;
  }
}

@media screen and (min-width: 992px) {
  .benefitsContainer {
    padding: 0 200px;
  }

  .boxImg {
    max-width: 100%;
    width: 25%;
  }
}


#contacts {
  background-color: #00a0c2;
  color: white;

  h1 {
    font-size: 55px;
    font-weight: 600;
  }

  h2 {
    font-weight: 600;
    line-height: 1.7;
  }

  a {
    color: rgb(206, 255, 206);
  }
}

.font-sans {
  font-family: 'Open Sans Condensed', sans-serif;
  font-display: swap;
}